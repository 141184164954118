window.Lube = (function($, ns) {
    'use strict';

    var cfg = {
        cache: {
            container: '[data-component="Lube.Carousel"]'
        },
        classes: {},
        events: {
            click: 'click'
        }
    };

    ns.Carousel = function(container) {
        this.container = container;
        this.settings = cfg;
        this.init();
    };

    ns.Carousel.prototype = {
        init: function() {
            this.cacheItems();
            this.bindEvents();
            this.activate();
        },
        cacheItems: function() {
            var cache = this.settings.cache;
        },
        bindEvents: function() {
            let settings = this.settings,
                events = settings.events,
                cache = settings.cache;
        },
        activate: function() {
            var settings = this.settings,
                options = settings.options,
                events = settings.events;

            this.container.bxSlider({
                mode: 'fade',
                controls: false,
                pager: false,
                auto: true
            });
        }
    };

    return ns;
})(window.jQuery, window.Lube || {});
